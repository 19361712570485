<template>
	<div class="label" v-if="!allignLabel && field.label">
		<ion-label>{{ field.label }}</ion-label>
	</div>
	<div :class="{input: !allignLabel, inputLabel: allignLabel}">
		<ion-label v-if="allignLabel && field.label">{{ field.label }}</ion-label>
		<vue-multiselect
			:class="{defaultBorder: validationState == null || !enabled, invalidBorder: enabled && validationState == false, validBorder: enabled && validationState == true}"
			v-model="field.value"
			ref="input"
			:placeholder="placeholder"
			:disabled="!field.enabled"
			:options="options"
			:track-by="optionField"
			:label="optionField"
			:searchable="searchable"
			:close-on-select="closeOnSelect"
			:show-labels="showLabels"
			:show-no-results="showNoResults"
			:selectLabel="selectLabel"
			:deselectLabel="deselectLabel"
			:selectedLabel="selectedLabel"
			@select="detectSelection"
			@remove="detectUnselection">
		</vue-multiselect>
		<ion-button fill="clear" v-if="canUnlock" @click="changeLockState">
			<ion-icon slot="icon-only" v-if="!field.enabled" :icon="lockOpenOutline"></ion-icon>
			<ion-icon slot="icon-only" v-else :icon="lockClosedOutline"></ion-icon>
		</ion-button>
		<ion-button fill="clear" v-if="canRefreshList" :disabled="!field.enabled" @click="$emit('refresh')">
			<ion-icon slot="icon-only" :icon="refreshCircleOutline"></ion-icon>
		</ion-button>
		<ion-button fill="clear" v-if="canAddItem" :disabled="!field.enabled" @click="$emit('addItem')">
			<ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
		</ion-button>
	</div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
/*
	* {
		margin-right: .5em;
	}
*/
	ion-icon {
		margin-right: 0;
	}
	ion-button {
		--padding-start:0;
		--padding-end:0;
	}
	ion-label {
		font-weight:bold;
		margin-top: .7em;
	}
	*:deep(.multiselect__content) {
		background:var(--ion-color-light) !important;
	}	
	.defaultBorder:deep(.multiselect__tags) {
		/*border: 1px solid #e8e8e8 !important;*/
		border: 1px solid var(--ion-color-light-shade) !important;
	}
	.invalidBorder:deep(.multiselect__tags) {
		border: 1px solid red !important;
	}
	.validBorder:deep(.multiselect__tags) {
		border: 1px solid green !important;
	}
	.label {
		margin-top: .5em;
		margin-left: .5em;
	}
	.input {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
	.inputLabel {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		text-align: left;
		margin-left: .5em;
		margin-top: .5em;
		margin-right: .5em;
	}
</style>

<script>
import { IonButton, IonIcon, IonLabel } from '@ionic/vue';
import { addCircleOutline, lockClosedOutline, lockOpenOutline, refreshCircleOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import VueMultiselect from 'vue-multiselect';

	export default defineComponent({
		props: {
			label: { type: String, default: "Label" },
			allignLabel: { type: Boolean, default: false },
			required: { type: Boolean, default: true },
			value: { type: Object, default: null },
			placeholder: { type: String, default: "Select Data" },
			enabled: { type: Boolean, default: true },
			options: { type: Array, default: () => [] },
			optionField: { type: String, default: null },
			searchable: { type: Boolean, default: true },
			closeOnSelect: { type: Boolean, default: true },
			showLabels: { type: Boolean, default: true },
			showNoResults: { type: Boolean, default: false },
			selectLabel: { type: String, default: "select" },
			deselectLabel: { type: String, default: "remove" },
			selectedLabel: { type: String, default: "Selected" },
			validationState: { type: Boolean, default: null },
			canAddItem: { type: Boolean, default: false },
			canRefreshList: { type: Boolean, default: false },
			canUnlock: { type: Boolean, default: false },
		},
		components: {
			IonLabel,
			IonButton,
			IonIcon,
			VueMultiselect,
		},
		emits: [ "selected", "unselected", "refresh", "addItem" ],
		data: () => ({
			field: {
				label: null,
				value: null,
				enabled: true,
			},
			addCircleOutline,
			refreshCircleOutline,
			lockOpenOutline,
			lockClosedOutline,
		}),
		mounted () {
			this.field.label = (!this.label) ? this.label : ((!this.required) ? this.label : this.label + "*");
			this.field.value = this.value;
			this.field.enabled = this.enabled;
		},
		watch: {
			label () {
				this.field.label = (!this.label) ? this.label : ((!this.required) ? this.label : this.label + "*");
			},
			value () {
				this.field.value = this.value;
			},
			enabled () {
				this.field.enabled = this.enabled;
			},
  },
		methods: {
			detectUnselection() {
				setTimeout(() => this.$emit('unselected'), 0);
			},
			/*
			detectSelection(value) {
				this.field.enabled = this.enabled;
				this.$emit('selected', value);
			},
			*/
			detectSelection() {
				this.field.enabled = this.enabled;
				setTimeout(() => this.emitSelection(), 0);
			},
			emitSelection() {
				this.$emit('selected', this.field.value);
			},
			changeLockState() {
				this.field.enabled = !this.field.enabled;
			},
		}
	});
</script>
